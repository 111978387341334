import React, { useState } from "react";
import { useEffect } from "react";
import Loader from "./components/findingloader";
import logoImage from "./assets/meal-unsplash.jpg";
import logoImageThin from "./assets/meal-unsplash-thin.png";
import logoImageThinBeta from "./assets/meal-unsplash-thin-beta.png";
import SignInModal from "./components/signinmodal";
import { getAuth, signOut } from "firebase/auth";
import { useMachine } from "@xstate/react";
import { loginStatusMachine } from "./machines/loginStatusMachine";
import { userMachine } from "./machines/userMachine";
import { menuMachine } from "./machines/menuMachine";
import { showModalMachine } from "./machines/showModalMachine";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUtensils,
  faSeedling,
  faCow,
  faFish,
  faAppleAlt,
  faCubesStacked,
  faTriangleExclamation,
  faPrint,
  faClock,
  faDownload,
  faUserCircle,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import FoodItem from "./components/foodItem";
import OtherFoodItems from "./components/otherfoodItems";
import DisplayLogo from "./components/logo";
import DisplayLogoHome from "./components/logohome";
import Footer from "./components/footer";
import "./App.css";
import MealTime from "./components/mealtime";
import RegisterModal from "./components/registermodal";
import Cuisine from "./components/cuisine";
import {
  ALLERGIES,
  createFruitTypes,
  createDairyTypes,
  createVeggieTypes,
  createMeatTypes,
} from "./constants";
import whiteIcon from "../src/assets/low-carb-meals-white-icon.png";

//const ipPort = "localhost:3001";
//const ipPort = "140.82.40.39"; // deployed
const ipPort = "lowcarbmeals.net"; // deployed

//=============================== SplashPage ======================================

function SplashPage({ loginStatusState, user, userSend, loginStatusSend }) {
  let history = useHistory();
  const [modalState, modalSend] = useMachine(showModalMachine);
  //console.log("Current State Entering Splash Page:", loginStatusState);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      /* console.log(
        "SplashPage loginStatusState in Use Effect:",
        loginStatusState
      ); */
      if (loginStatusState === "isLoggedIn") {
        //console.log("Current State:", loginStatusState);
        history.push("/home");
      }
    }, 100);

    // Return a function that clears the timeout to prevent memory leaks
    return () => clearTimeout(timeoutId);
  }, [history, loginStatusState]);

  const toggleModal = (eventType) => {
    modalSend({ type: eventType });
  };

  const isSignInModalVisible = modalState.matches("signinVisible");
  const isRegisterModalVisible = modalState.matches("registerVisible");

  return (
    <>
      <div className="flex min-h-screen flex-col items-center justify-center bg-gradient-to-b from-[#CBC8CC] to-[#F1EDF2]">
        <div className="relative container flex flex-col items-center justify-center gap-4 px-4 py-4 lg:w-3/5">
          <DisplayLogo src={logoImage} />
          {isSignInModalVisible && (
            <div className="absolute inset-0 flex items-center justify-center">
              <SignInModal
                isOpen={isSignInModalVisible}
                onClose={() => toggleModal("TOGGLE_SIGNIN_MODAL")}
                userSend={userSend}
                loginStatusSend={loginStatusSend}
                currentState={loginStatusState.value}
              >
                <h2 className="text-2xl mb-4">Sign In</h2>
                <p className="text-gray-700">Coming Soon...</p>
              </SignInModal>
            </div>
          )}
          {isRegisterModalVisible && (
            <div className="absolute inset-0 flex items-center justify-center">
              <RegisterModal
                isOpen={isRegisterModalVisible}
                onClose={() => toggleModal("TOGGLE_REGISTER_MODAL")}
                userSend={userSend}
                loginStatusSend={loginStatusSend}
                currentState={loginStatusState.value}
              ></RegisterModal>
            </div>
          )}
        </div>
        <div className="flex flex-col sm:flex-row items-center">
          {" "}
          {/* Add this container div */}
          <button
            id="login-button"
            className="bg-custom-300 text-black active:bg-amber-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-2 ease-linear transition-all duration-150 w-full sm:w-auto align-middle"
            onClick={() => toggleModal("TOGGLE_SIGNIN_MODAL")}
          >
            SIGN IN
          </button>
          <button
            id="create-account-button"
            className="bg-custom-300 text-black active:bg-amber-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-2 ease-linear transition-all duration-150 w-full sm:w-auto align-middle"
            onClick={() => toggleModal("TOGGLE_REGISTER_MODAL")}
          >
            SIGN UP
          </button>
        </div>{" "}
        {/* Close the container div */}
        <Footer />
      </div>
    </>
  );
}

//=================================== HomePage =======================================

function HomePage({ loginStatusSend, menuState, menuSend }) {
  const [response, setResponse] = useState("");
  const [checkedItems, setCheckedItems] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [, setParams] = useState({});
  const [paramFromInput, setParamFromInput] = useState("");
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [selectedCuisine, setSelectedCuisine] = useState(null);
  const [recipeTitle, setRecipeTitle] = useState("");
  const [attempt, setAttempt] = useState(false);
  const abortController = new AbortController();

  //console.log("in homepage: ", menuState.value);
  const toggleMenu = (eventType) => {
    //console.log("inside toggle menu");
    menuSend({ type: eventType });
  };

  const maxRetries = 3;
  let retries = 0;

  let history = useHistory();

  // Get user info
  const auth = getAuth();
  const firebaseUser = auth.currentUser;
  //console.log("FirebaseUser: ", firebaseUser);
  let displayName = "";
  let email = "";
  //let firstName = "";
  // temporary hold split name
  let names = [];

  if (firebaseUser !== null && firebaseUser.displayName !== null) {
    // The user object has basic properties such as display name, email, etc.

    displayName = firebaseUser.displayName;
    //console.log("Firebase DisplayName: ", firebaseUser.displayName);
    //displayName = names[0] ? displayName.split(" ") : displayName;
    names = displayName.split(" ");
    //console.log("First Name", names[0]);
    displayName = names[0];
    //console.log("Display Name: ", displayName);

    email = firebaseUser.email;
    //const photoURL = user.photoURL;
    //const emailVerified = user.emailVerified;

    // The user's ID, unique to the Firebase project. Do NOT use
    // this value to authenticate with your backend server, if
    // you have one. Use User.getToken() instead.
    //const uid = firebaseUser.uid;
  } else {
    displayName = "back!";
    email = "";
  }

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign out successful, send LOGOUT event to the loginStatusMachine
        loginStatusSend({ type: "LOGOUT" });
        toggleMenu("TOGGLE");
        // Navigate back to root
        history.push("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const params = new URLSearchParams(paramFromInput);
      for (const key in checkedItems) {
        if (checkedItems[key]) {
          //console.log("Key:", key);
          params.append("food", key);
        }
      }

      params.append("food", paramFromInput);

      if (selectedMeal) {
        params.append("meal", selectedMeal);
        if (selectedMeal !== "breakfast") {
          params.append(
            "spices",
            "please use more spices than just salt and pepper"
          );
        }
      }

      if (selectedCuisine) {
        params.append("cuisine", `with ${selectedCuisine} cooking style.`);
      }

      if (attempt) {
        params.append(
          "attempt",
          "Please suggest a completely different recipe than the last response."
        );
      }

      let data;
      let abortFetch = false;

      while (retries < maxRetries && !abortFetch) {
        try {
          const response = await fetch(`https://${ipPort}/api?${params}`, {
            method: "GET",
            signal: abortController.signal, // Pass the signal property to the fetch call
          });
          data = await response.json();
          // handle the response data
          break; // exit the loop if successful
        } catch (error) {
          // Check if the error is due to an abort signal
          if (error.name === "AbortError") {
            console.log("Fetch aborted");
            abortFetch = true;
            break;
          }

          console.error("Error fetching data:", error.message.innerHTML);
          retries++;
          if (retries === maxRetries) {
            // handle the error condition
            data.text =
              'I\'m sorry, an error has occured. Please click the "Try Again" button.';
            break; // exit the loop if maximum retries reached
          }
          await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for 1 second before retrying
        }
      }

      formatResponseForDisplay(data.text);

      //console.log(data.text);
      const lines = data.text.split("\n");
      const firstLine = lines[2];
      //console.log("First line: ", firstLine);
      setRecipeTitle(firstLine);
      //console.log("FrontEndResponse: ", data.text);

      let tempString = "";
      tempString = formatResponseForDisplay(data.text);

      data.text = tempString;

      setResponse(data.text); // Update the response state variable
      setIsLoading(false);
    } catch (error) {
      setResponse(error);
      setIsLoading(false);
    }
  };

  function formatResponseForDisplay(str) {
    let lines = str.split("\n");
    lines.shift();
    let newOutputString = "";

    lines.forEach((element) => {
      //console.log("line " + element);
      // Start formatting logic
      // Bold Recipe Name
      if (/^Recipe Name:/.test(element)) {
        element = `<br><br><b><u>${element}</u></b>`;
      }
      // Bold Ingredients
      if (/^Ingredients/.test(element)) {
        element = `<br><b>${element}</b>`;
      }
      // Bold Instructions
      if (/^Instructions/.test(element)) {
        element = `<br><b>${element}</b>`;
      }
      // Bold Nutrition Information
      if (/^Nutrition Information/.test(element)) {
        element = `<br><b>${element}</b>`;
      }
      // italicize Instructions
      if (/^\d+/.test(element)) {
        element = `<i>${element}</i>`;
      }

      // Underline Nutrition Information
      if (
        /^Servings/.test(element) ||
        /^Calories Per Serving/.test(element) ||
        /^Total Fat Per Serving/.test(element) ||
        /^Carbs Per Serving/.test(element) ||
        /^Protein Per Serving/.test(element) ||
        /^Sodium Per Serving/.test(element)
      ) {
        let itemAndValue = [];
        itemAndValue = element.split(":");
        element = `<sub><b>${itemAndValue[0]}</b></sub>&nbsp;<sub>${itemAndValue[1]}</sub>`;
      }

      newOutputString += `${element}<br>`;
    });
    return newOutputString;
  }

  const handleCheckChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevItems) => ({ ...prevItems, [name]: checked }));
  };

  const onChangeValue = (event) => {
    //console.log(event.target.value);
    setSelectedMeal(event.target.value);
  };

  const onChangeCuisine = (event) => {
    setSelectedCuisine(event.target.value);
  };

  const meatTypes = createMeatTypes(handleCheckChange);

  function MeatList(props) {
    const meatItems = props.meats.map((meat) => (
      <FoodItem
        key={meat.id}
        name={meat.name}
        value={meat.value}
        checked={checkedItems[meat.name]}
        onChange={handleCheckChange}
      />
    ));
    return <div className="text-md flex flex-wrap">{meatItems}</div>;
  }

  const veggieTypes = createVeggieTypes(handleCheckChange);

  function VeggieList(props) {
    const veggieItems = props.veggies.map((veggie) => (
      <FoodItem
        key={veggie.id}
        name={veggie.name}
        value={veggie.value}
        checked={checkedItems[veggie.name]}
        onChange={handleCheckChange}
      />
    ));
    return <div className="text-md flex flex-wrap">{veggieItems}</div>;
  }

  const dairyTypes = createDairyTypes(handleCheckChange);

  function DairyList(props) {
    const dairyItems = props.dairyItems.map((dairyItem) => (
      <FoodItem
        key={dairyItem.id}
        name={dairyItem.name}
        value={dairyItem.value}
        checked={checkedItems[dairyItem.name]}
        onChange={handleCheckChange}
      />
    ));
    return <div className="text-md flex flex-wrap">{dairyItems}</div>;
  }

  const fruitTypes = createFruitTypes(handleCheckChange);

  function FruitList(props) {
    const fruitItems = props.fruits.map((fruit) => (
      <FoodItem
        key={fruit.id}
        name={fruit.name}
        value={fruit.value}
        checked={checkedItems[fruit.name]}
        onChange={handleCheckChange}
      />
    ));
    return <div className="text-md flex flex-wrap">{fruitItems}</div>;
  }

  function resetState() {
    setResponse("");
  }

  function nextAttempt() {
    setAttempt(true);
  }

  const handleClick = () => {
    resetState();
    nextAttempt();
  };

  function print() {
    //let title = document.getElementById("title");
    let titleimage = document.getElementById("title-image");
    let button = document.getElementById("try-button");

    //title.style.display = "none";
    titleimage.style.display = "none";
    button.style.display = "none";
    window.print();
    //title.style.display = "";
    titleimage.style.display = "";
    button.style.display = "";
  }

  function save() {
    //let title = document.getElementById("title");
    let titleimage = document.getElementById("title-image");
    let utensils = document.getElementById("utensils");
    let download = document.getElementById("download");
    let print = document.getElementById("print");
    let warning = document.getElementById("warning");
    let trybutton = document.getElementById("try-button");
    let footer = document.getElementById("footer");
    let usericon = document.getElementById("user-circle");
    let welcome = document.getElementById("welcome");
    let account = document.getElementById("account");

    usericon.style.display = "none";
    titleimage.style.display = "none";
    utensils.style.display = "none";
    download.style.display = "none";
    print.style.display = "none";
    warning.style.display = "none";
    trybutton.style.display = "none";
    footer.style.display = "none";
    welcome.style.display = "none";
    account.style.display = "none";

    var htmlContent = document.body.innerHTML;
    var bl = new Blob([htmlContent], { type: "text/html" });

    var a = document.createElement("a");
    a.href = URL.createObjectURL(bl);
    let recipeTitleForSave = recipeTitle;
    // remove the colon
    recipeTitleForSave = recipeTitleForSave.replaceAll(":", "");
    // remove newlines
    recipeTitleForSave = recipeTitleForSave.replaceAll(/\n/g, "");
    // trim
    recipeTitleForSave = recipeTitleForSave.trim();
    // replace any remaining white space with underscores
    recipeTitleForSave = recipeTitleForSave.replaceAll(" ", "_");
    //console.log("Recipe Title: ", recipeTitleForSave);

    a.download = `${recipeTitleForSave}.html`;
    a.hidden = true;
    document.body.appendChild(a);
    a.textContent = "Download Recipe";
    a.click();
    usericon.style.display = "";
    titleimage.style.display = "";
    utensils.style.display = "";
    download.style.display = "";
    print.style.display = "";
    warning.style.display = "";
    trybutton.style.display = "";
    footer.style.display = "";
    welcome.style.display = "";
    account.style.display = "";
  }

  const handleReset = (event) => {
    setCheckedItems("");
    setParams("");
    setSelectedMeal("");
    setSelectedCuisine("");
    setParamFromInput("");
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <main className="flex min-h-screen flex-col items-center pt-2 justify-center bg-gradient-to-b from-[#CBC8CC] to-[#F1EDF2]">
          <div className="container flex flex-col items-center justify-center gap-4 px-4 pb-0 lg:w-3/5 ">
            <div className="flex justify-between gap-4 pl-2 pr-2 pt-1 text-black  bg-white font-bold w-full flex-wrap rounded-tl-md rounded-tr-md items-center">
              <div className="flex items-center text-sm font-bold rounded-sm  pl-0">
                <img
                  src={whiteIcon}
                  alt="White Icon"
                  className="w-10 h-10" // Change the numbers to control the size
                />
                <span title={email} id="welcome">
                  Welcome
                  <span className="pl-1 pr-1">{displayName}</span>
                </span>
              </div>
              <div className="relative text-sm">
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="pr-1 "
                  id="user-circle"
                />
                <div
                  className="inline-flex items-center cursor-pointer"
                  onClick={() => toggleMenu("TOGGLE")}
                  id="account"
                >
                  Account
                </div>
                {menuState.value === "open" && (
                  <div
                    className={`absolute right-0 mt-2 w-48 py-2 bg-white rounded-sm shadow-md z-10 transition-opacity duration-600 ease-in ${
                      menuState.value === "open"
                        ? "opacity-100 visible"
                        : "opacity-0 invisible"
                    }`}
                  >
                    {/* Manage Account */}
                    <div>
                      <div
                        id="manage"
                        className="flex justify-end cursor-pointer text-sm p-2 transition-all duration-150"
                        onClick={() => toggleMenu("TOGGLE")}
                      >
                        <span className="text-[8px] pr-1 italic">
                          (Coming Soon)
                        </span>
                        <span className="hover:underline">Manage Account</span>
                      </div>
                    </div>

                    {/* Signout */}
                    <div>
                      <div
                        id="signout"
                        className="flex justify-end cursor-pointer text-sm p-2 hover:underline transition-all duration-150"
                        onClick={handleSignOut}
                      >
                        Sign Out
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="container flex flex-col items-center justify-center gap-4 px-4 pb-0 lg:w-3/5 ">
            <DisplayLogoHome src={logoImageThinBeta} />
            {/* response is received */}
            {response ? (
              <>
                <div
                  id="response"
                  className="container flex flex-col items-center justify-center gap-4 pb-1 w-full"
                >
                  <div className="container flex flex-col items-center justify-center  w-full">
                    {/* Other Foods */}
                    <div className="flex-col gap-4 rounded-xl bg-white p-4 text-black shadow-lg w-full flex-wrap">
                      <div>
                        <span className="float-left">
                          <h3 className="text-2xl font-bold">
                            Suggested Recipe{" "}
                            <FontAwesomeIcon
                              icon={faUtensils}
                              id={"utensils"}
                            />
                          </h3>
                        </span>
                        <span className="float-right pr-4 align-middle">
                          <h3 className="text-2xl font-bold cursor-pointer">
                            <FontAwesomeIcon
                              icon={faPrint}
                              onClick={print}
                              id={"print"}
                            />
                          </h3>
                        </span>
                        <span className="float-right pr-4 align-middle">
                          <h3 className="text-2xl font-bold cursor-pointer">
                            <FontAwesomeIcon
                              icon={faDownload}
                              onClick={save}
                              id={"download"}
                            />
                          </h3>
                        </span>
                        <br />
                        <br />
                      </div>
                      <div className="text-1xl font-bold bg-custom-100 rounded-xl p-4 shadow-md">
                        <div className="pb-1">
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className="text-red-500"
                            id={"warning"}
                          />{" "}
                          Warning!
                        </div>
                        <div className="text-sm font-lg leading-6 font-medium">
                          {ALLERGIES}
                        </div>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: response, //replaceAll("\n", "<br>"),
                        }}
                      ></div>
                      <br />
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    id="try-button"
                    className="bg-custom-300 text-black active:bg-amber-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-2 ease-linear transition-all duration-150"
                    onClick={handleClick}
                  >
                    Try Again
                  </button>
                </div>
              </>
            ) : (
              /* no response yet paint form */
              <>
                {/*Cusine Type */}
                <div className="w-full flex flex-col items-center justify-center rounded-xl px-4  bg-white p-4 text-black shadow-lg">
                  <h3 className="text-2xl font-bold">
                    <FontAwesomeIcon icon={faEarthAmericas} /> Cuisine
                  </h3>
                  <div className="flex flex-wrap  w-full">
                    <Cuisine
                      id="1"
                      name="cuisine"
                      value="American"
                      checked={selectedCuisine === "American"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="2"
                      name="cuisine"
                      value="Brazilian"
                      checked={selectedCuisine === "Brazilian"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="3"
                      name="cuisine"
                      value="Chinese"
                      checked={selectedCuisine === "Chinese"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="4"
                      name="cuisine"
                      value="Cuban"
                      checked={selectedCuisine === "Cuban"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="5"
                      name="cuisine"
                      value="English"
                      checked={selectedCuisine === "English"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="6"
                      name="cuisine"
                      value="Ethiopian"
                      checked={selectedCuisine === "Ethiopian"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="7"
                      name="cuisine"
                      value="Filipino"
                      checked={selectedCuisine === "Filipino"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="8"
                      name="cuisine"
                      value="French"
                      checked={selectedCuisine === "French"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="9"
                      name="cuisine"
                      value="Greek"
                      checked={selectedCuisine === "Greek"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="10"
                      name="cuisine"
                      value="Indian"
                      checked={selectedCuisine === "Indian"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="11"
                      name="cuisine"
                      value="Italian"
                      checked={selectedCuisine === "Italian"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="12"
                      name="cuisine"
                      value="Japanese"
                      checked={selectedCuisine === "Japanese"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="13"
                      name="cuisine"
                      value="Korean"
                      checked={selectedCuisine === "Korean"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="14"
                      name="cuisine"
                      value="Lebanese"
                      checked={selectedCuisine === "Lebanese"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="15"
                      name="cuisine"
                      value="Mexican"
                      checked={selectedCuisine === "Mexican"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="16"
                      name="cuisine"
                      value="Moroccan"
                      checked={selectedCuisine === "Moroccan"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="17"
                      name="cuisine"
                      value="Peruvian"
                      checked={selectedCuisine === "Peruvian"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="18"
                      name="cuisine"
                      value="Portuguese"
                      checked={selectedCuisine === "Portuguese"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="19"
                      name="cuisine"
                      value="Spanish"
                      checked={selectedCuisine === "Spanish"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="20"
                      name="cuisine"
                      value="Thai"
                      checked={selectedCuisine === "Thai"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="21"
                      name="cuisine"
                      value="Turkish"
                      checked={selectedCuisine === "Turkish"}
                      onChange={onChangeCuisine}
                    />
                    <Cuisine
                      id="22"
                      name="cuisine"
                      value="Vietnamese"
                      checked={selectedCuisine === "Vietnamese"}
                      onChange={onChangeCuisine}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  {/* Protein */}
                  <div className="flex-col gap-4 rounded-xl bg-white p-4 text-black shadow-lg">
                    <h3 className="text-2xl font-bold">
                      <FontAwesomeIcon icon={faFish} /> Protein
                    </h3>
                    <div className="text-md flex flex-wrap">
                      <MeatList meats={meatTypes} />
                    </div>
                  </div>
                  {/* Veggies */}
                  <div className="flex-col gap-4 rounded-xl bg-white p-4 text-black shadow-lg">
                    <h3 className="text-2xl font-bold">
                      <FontAwesomeIcon icon={faSeedling} /> Vegetables
                    </h3>
                    <div className="text-md flex flex-wrap">
                      <VeggieList veggies={veggieTypes} />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  {/* Dairy */}
                  <div className="flex-col gap-4 rounded-xl bg-white p-4 text-black shadow-lg">
                    <h3 className="text-2xl font-bold">
                      <FontAwesomeIcon icon={faCow} /> Dairy
                    </h3>
                    <div className="text-md flex flex-wrap">
                      <DairyList dairyItems={dairyTypes} />
                    </div>
                  </div>
                  {/* Fruit */}
                  <div className="flex-col gap-4 rounded-xl bg-white p-4 text-black shadow-lg">
                    <h3 className="text-2xl font-bold">
                      <FontAwesomeIcon icon={faAppleAlt} /> Fruit
                    </h3>
                    <div className="text-md flex flex-wrap">
                      <FruitList fruits={fruitTypes} />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 w-full">
                  {/* Other Ingredients */}
                  <div className="flex-col gap-4 rounded-xl bg-white p-4 text-black shadow-lg h-56">
                    <h3 className="text-2xl font-bold">
                      <FontAwesomeIcon icon={faCubesStacked} /> Other
                      Ingredients?
                    </h3>
                    <div className="text-md ">
                      <OtherFoodItems
                        paramFromInput={paramFromInput}
                        setParamFromInput={setParamFromInput}
                      />
                    </div>
                  </div>
                  {/* Which Meal */}
                  <div className="flex-col gap-4 rounded-xl bg-white p-4 mb-4 text-black shadow-lg h-56">
                    <h3 className="text-2xl font-bold">
                      <FontAwesomeIcon icon={faClock} /> Which Meal?
                    </h3>
                    <div className="text-md pb-4">
                      <MealTime
                        id="1"
                        name="mealTime"
                        value="breakfast"
                        checked={selectedMeal === "breakfast"}
                        onChange={onChangeValue}
                      />
                      <MealTime
                        id="2"
                        name="mealTime"
                        value="lunch"
                        checked={selectedMeal === "lunch"}
                        onChange={onChangeValue}
                      />
                      <MealTime
                        id="3"
                        name="mealTime"
                        value="dinner"
                        checked={selectedMeal === "dinner"}
                        onChange={onChangeValue}
                      />
                      <MealTime
                        id="4"
                        name="mealTime"
                        value="dessert"
                        checked={selectedMeal === "dessert"}
                        onChange={onChangeValue}
                      />
                    </div>
                  </div>
                </div>
                <span>
                  {/* reset state */}
                  <button
                    className="bg-custom-300 text-black active:bg-amber-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-2 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleReset}
                  >
                    Reset Form
                  </button>
                  {/* submit form */}
                  <button
                    className="bg-custom-300 text-black active:bg-amber-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-2 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Find A Recipe
                  </button>
                </span>
              </>
            )}
            <Footer />
            <div>
              {isLoading && (
                <Loader
                  setIsLoading={setIsLoading}
                  abortController={abortController}
                />
              )}
            </div>
          </div>
        </main>
      </form>
    </>
  );
}

//=================================== App =======================================

function App() {
  const [loginStatusState, loginStatusSend] = useMachine(loginStatusMachine);
  const [menuState, menuSend] = useMachine(menuMachine);
  const [userState, userSend] = useMachine(userMachine);

  //console.log("App.js Current Menu State:", menuState.value);
  //console.log("App.js Current loginstatus State:", loginStatusState.value);

  return (
    <div>
      <Router>
        <Route
          exact
          path="/"
          render={(props) => (
            <SplashPage
              {...props}
              loginStatusState={loginStatusState.value}
              loginStatusSend={loginStatusSend}
              user={userState.context.user}
              userSend={userSend}
            />
          )}
        />
        <Route
          path="/home"
          render={(props) => (
            <HomePage
              {...props}
              loginStatusState={loginStatusState.value}
              loginStatusSend={loginStatusSend}
              user={userState.context.user}
              userSend={userSend}
              menuState={menuState}
              menuSend={menuSend}
            />
          )}
        />
      </Router>
    </div>
  );
}

export default App;
