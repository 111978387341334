import React from "react";

function MealTime(props) {
  const { id, name, value, checked, onChange } = props;
  return (
    <div className="flex-col">
      <span className="flex p-1">
        <span className="flex items-center gap-1">
          <input
            type="radio"
            className="form-radio h-5 w-5 text-gray-600"
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
          />
          {id === "1" && "Breakfast Time"}
          {id === "2" && "Lunch Time"}
          {id === "3" && "Dinner Time"}
          {id === "4" && "Dessert Time"}
        </span>
      </span>
    </div>
  );
}

export default MealTime;
