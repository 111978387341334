import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="flex flex-col items-center gap-2 m-4">
      <div className="text-sm text-black" id="footer">
        <div className="flex flex-wrap gap-2 sm:flex-nowrap justify-center text-center">
          <span className="p-2 font-bold">
            <Link
              to={{ pathname: "mailto://Info@LowCarbMeals.net" }}
              target="_blank"
            >
              Info@LowCarbMeals.net
            </Link>
          </span>
          <span className="p-2 font-bold">
            ©{currentYear}&nbsp;
            <Link to={{ pathname: "http://LowCarbMeals.net" }} target="_blank">
              LowCarbMeals.net
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
