import React from "react";
import Register from "./register";
const RegisterModal = ({
  isOpen,
  onClose,
  userSend,
  loginStatusSend,
  currentState,
}) => {
  //console.log("signinmodal loginStatusSend:", loginStatusSend); // Add console log statement

  // Add userSend as a prop
  if (!isOpen) {
    return null;
  }
  //console.log("In the SignInModal. CurrentState: ", currentState);
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-md">
      <div className="bg-white p-6 rounded shadow-lg w-10/12 md:w-2/3 lg:w-1/2">
        <Register
          currentState={currentState}
          userSend={userSend}
          loginStatusSend={loginStatusSend}
          onClose={onClose}
        />{" "}
        {/* Pass userSend to Signin */}
      </div>
    </div>
  );
};

export default RegisterModal;
