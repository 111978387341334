import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { useState } from "react";
import whiteIcon from "../assets/low-carb-meals-white-icon.png";

const Register = ({ currentState, onClose, userSend, loginStatusSend }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const auth = getAuth();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = document.querySelector("#sign-up-email").value;
    const password = document.querySelector("#password").value;
    const password2 = document.querySelector("#password2").value;
    const firstName = document.querySelector("#first-name").value;
    const lastName = document.querySelector("#last-name").value;
    const fullName = `${firstName} ${lastName}`;

    if (password === "") {
      setErrorMessage("Password cannot be blank! Please try again.");
      return;
    }

    if (password.length < 8) {
      setErrorMessage(
        "Password must be 8 characters or more! Please try again."
      );
      return;
    }

    if (password !== password2) {
      setErrorMessage("Passwords do not match! Please try again.");
      return;
    }

    //console.log(email, password, password2);
    setErrorMessage("");

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in

        updateProfile(userCredential.user, { fullName })
          .then(() => {
            //console.log("User display name updated");
            //console.log(userCredential.user);
            //console.log(userCredential.user.displayName);
          })
          .catch((error) => {
            //console.log(error);
          });

        onClose();
      })
      .catch((error) => {
        const errorMessage = error.message;
        setErrorMessage(errorMessage);
      });
  };

  return (
    <div className="w-full">
      <form id="sign-up" className="flex flex-col" onSubmit={handleSubmit}>
        <div className="pb-4 text-black font-bold ">
          <div className="flex items-center">
            <img
              src={whiteIcon}
              alt="White Icon"
              className="w-16 h-16" // Change the numbers to control the size
            />
            <span className="ml-2">Sign Up For LowCarbMeals</span>
          </div>
        </div>
        <div className="w-full flex flex-col space-y-2 mb-4">
          <span className="text-red-700 !important text-md">
            *Password must be 8 or more characters
          </span>
        </div>
        <div className="w-full flex flex-col space-y-2 mb-2">
          {errorMessage && (
            <div className="text-red-500 text-sm">{errorMessage}</div>
          )}
          <span className="text-black font-bold">First Name</span>
          <input
            className="text-black border-slate-300 border-2 p-1 w-full rounded-md"
            type={"text"}
            id="first-name"
            placeholder="First Name"
            autoFocus
          ></input>
          <span className="text-black font-bold">Last Name</span>
          <input
            className="text-black border-slate-300 border-2 p-1 w-full rounded-md"
            type={"text"}
            id="last-name"
            placeholder="Last Name"
            autoFocus
          ></input>
          <span className="text-black font-bold">Email</span>
          <input
            className="text-black border-slate-300 border-2 p-1 w-full rounded-md"
            type={"email"}
            placeholder="Email"
            id="sign-up-email"
          ></input>
          <span className="text-black font-bold">Password</span>
          <input
            className="text-black border-slate-300 border-2 p-1 w-full rounded-md"
            type={passwordVisible ? "text" : "password"}
            id="password"
            placeholder="Enter Account Password"
          ></input>
          <span className="text-black font-bold">Confirm Password</span>
          <input
            className="text-black border-slate-300 border-2 p-1 w-full rounded-md"
            type={passwordVisible ? "text" : "password"}
            id="password2"
            placeholder="Re-enter Account Password"
          ></input>
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="mt-2 text-left text-sm text-red-500 focus:outline-none"
          >
            {passwordVisible ? "Hide Password" : "Show Password"}
          </button>
        </div>

        <div className="w-full flex text-cen flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 space-x-0 sm:space-x-2">
          <button
            className="bg-custom-300 text-black active:bg-amber-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-2 ease-linear transition-all duration-150 w-full sm:w-auto"
            type="submit"
          >
            Sign Up
          </button>
          <button
            className="bg-custom-300 text-black active:bg-amber-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-2 ease-linear transition-all duration-150 w-full sm:w-auto"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default Register;
