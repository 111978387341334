import React from "react";

function DisplayLogoHome(props) {
  const { src: logoImage } = props;

  return (
    <div className="relative mb-1">
      <div className="h-2/6">
        <img
          src={logoImage}
          className="max-w-full sm:w-25 rounded-bl-md rounded-br-md object-scale-down shadow-lg"
          alt="By Travis Yewell on Unsplash"
          id="title-image"
        />
      </div>
    </div>
  );
}

export default DisplayLogoHome;
