import React from "react";

function Cuisine(props) {
  const { id, name, value, checked, onChange } = props;
  return (
    <div className="flex">
      <span className="flex p-1">
        <span className="flex items-center gap-1">
          <input
            type="radio"
            className="form-radio h-5 w-5 text-gray-600"
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
          />
          {id === "1" && "American"}
          {id === "2" && "Brazilian"}
          {id === "3" && "Chinese"}
          {id === "4" && "Cuban"}
          {id === "5" && "English"}
          {id === "6" && "Ethiopian"}
          {id === "7" && "Filipino"}
          {id === "8" && "French"}
          {id === "9" && "Greek"}
          {id === "10" && "Indian"}
          {id === "11" && "Italian"}
          {id === "12" && "Japanese"}
          {id === "13" && "Korean"}
          {id === "14" && "Lebanese"}
          {id === "15" && "Mexican"}
          {id === "16" && "Moroccan"}
          {id === "17" && "Peruvian"}
          {id === "18" && "Portuguese"}
          {id === "19" && "Spanish"}
          {id === "20" && "Thai"}
          {id === "21" && "Turkish"}
          {id === "22" && "Vietnamese"}
        </span>
      </span>
    </div>
  );
}

export default Cuisine;
