// Protein
export function createMeatTypes(handleCheckChange) {
  return [
    {
      id: 1,
      name: "Steak",
      value: "steak",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 2,
      name: "Pork Loin",
      value: "pork loin",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 3,
      name: "Pork Chops",
      value: "pork chops",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 4,
      name: "Chicken Breast",
      value: "chicken breast",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 5,
      name: "Fish",
      value: "fish",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 6,
      name: "Hamburger",
      value: "hamburger",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 7,
      name: "Ground Turkey",
      value: "ground turkey",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 8,
      name: "Lamb",
      value: "lamb",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 9,
      name: "Turkey",
      value: "turkey",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 10,
      name: "Duck",
      value: "duck",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 11,
      name: "Venison",
      value: "venison",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 12,
      name: "Whole Chicken",
      value: "whole chicken",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 13,
      name: "Haddock",
      value: "haddock",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 14,
      name: "Salmon",
      value: "salmon",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 15,
      name: "Tuna",
      value: "tuna",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 16,
      name: "Eggs",
      value: "eggs",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 17,
      name: "Chicken Thighs",
      value: "Chicken Thighs",
      checked: false,
      onchange: handleCheckChange,
    },
  ];
}
// Veggies
export function createVeggieTypes(handleCheckChange) {
  return [
    {
      id: 1,
      name: "Broccoli",
      value: "broccoli",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 2,
      name: "Carrots",
      value: "carrots",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 3,
      name: "Cauliflower",
      value: "cauliflower",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 4,
      name: "Cucumbers",
      value: "cucumbers",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 5,
      name: "Green beans",
      value: "green-beans",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 6,
      name: "Peppers",
      value: "peppers",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 7,
      name: "Spinach",
      value: "spinach",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 8,
      name: "Tomatoes",
      value: "tomatoes",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 9,
      name: "Onion",
      value: "onion",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 10,
      name: "Scallions",
      value: "scallions",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 11,
      name: "Corn",
      value: "corn",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 12,
      name: "Potatos",
      value: "potatos",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 13,
      name: "Lettuce",
      value: "lettuce",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 14,
      name: "Zucchini",
      value: "Zucchini",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 15,
      name: "Squash",
      value: "Squash",
      checked: false,
      onchange: handleCheckChange,
    },
  ];
}
// Dairy
export function createDairyTypes(handleCheckChange) {
  return [
    { id: 1, name: "Milk", value: "milk", onchange: handleCheckChange },
    { id: 2, name: "Cheese", value: "cheese", onchange: handleCheckChange },
    { id: 3, name: "Butter", value: "butter", onchange: handleCheckChange },
    { id: 4, name: "Yogurt", value: "yogurt", onchange: handleCheckChange },
    {
      id: 5,
      name: "Ice Cream",
      value: "ice-cream",
      onchange: handleCheckChange,
    },
    {
      id: 6,
      name: "Sour Cream",
      value: "sour cream",
      onchange: handleCheckChange,
    },
    {
      id: 7,
      name: "Cream Cheese",
      value: "cream cheese",
      onchange: handleCheckChange,
    },
    {
      id: 8,
      name: "Cottage Cheese",
      value: "cottage cheese",
      onchange: handleCheckChange,
    },
    {
      id: 9,
      name: "Goat Cheese",
      value: "goat cheese",
      onchange: handleCheckChange,
    },
  ];
}

// Fruit
export function createFruitTypes(handleCheckChange) {
  return [
    {
      id: 1,
      name: "Apple",
      value: "apple",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 2,
      name: "Banana",
      value: "banana",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 3,
      name: "Orange",
      value: "orange",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 4,
      name: "Grapefruit",
      value: "grapefruit",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 5,
      name: "Peach",
      value: "peach",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 6,
      name: "Pineapple",
      value: "pineapple",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 7,
      name: "Watermelon",
      value: "watermelon",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 8,
      name: "Mango",
      value: "mango",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 9,
      name: "Cranberries",
      value: "cranberries",
      checked: false,
      onchange: handleCheckChange,
    },
    {
      id: 10,
      name: "Lemon",
      value: "lemon",
      checked: false,
      onchange: handleCheckChange,
    },
  ];
}

// Allergy Statement
export const ALLERGIES =
  "The suggested recipes may contain ingredients that can cause allergic reactions in some individuals. It is important to carefully review all ingredients before preparing and consuming any recipe. Common food allergens include peanuts, tree nuts, eggs, milk, soy, wheat, fish, and shellfish. Please consult with a medical professional if you have any concerns about food allergies. We are not responsible for any adverse reactions caused by consuming the suggested recipes.";
